import {
  GetAccountDetailsQuery,
  GetTeamProjectsDetailsQuery,
  GetUserDetailsQuery,
  ProductName,
  ProjectRolesUpdate,
} from '@pypestream/api-services/urql';
import { assign } from '@xstate/immer';
import { compact, sortBy } from 'lodash';
import { ManagerContext, ManagerEvents } from './manager.xstate';

// #region Types
export type UserTeams = NonNullable<
  GetUserDetailsQuery['tenancy_users_by_pk']
>['_user_teams'];

export type ProjectProductRoles = NonNullable<
  NonNullable<
    NonNullable<GetAccountDetailsQuery['admin_']>['accountById']
  >['roles']
>['rows'];

type AccountRoles = Array<
  NonNullable<
    NonNullable<
      GetUserDetailsQuery['tenancy_users_by_pk']
    >['user_accounts'][number]
  >['_user_account_roles'][number] & {
    teams?: UserTeams;
  }
>;

export type AccountTeams = NonNullable<
  NonNullable<
    NonNullable<GetAccountDetailsQuery['admin_']>['accountById']
  >['teams']
>['rows'];

export type AccountProjects = NonNullable<
  NonNullable<
    NonNullable<GetAccountDetailsQuery['admin_']>['accountById']
  >['projects']
>['rows'];

type UserAccountProjectWithoutRolesType = Omit<
  NonNullable<
    GetUserDetailsQuery['tenancy_users_by_pk']
  >['user_accounts'][number]['user_account_projects'][number],
  '_user_account_project_roles'
>;

export type ProjectProductSettings = NonNullable<
  GetUserDetailsQuery['tenancy_users_by_pk']
>['user_accounts'][number]['user_account_projects'][number]['project']['project_product_settings'];

export type UserAccountProject = UserAccountProjectWithoutRolesType & {
  _user_account_project_roles: Array<
    NonNullable<
      GetUserDetailsQuery['tenancy_users_by_pk']
    >['user_accounts'][number]['user_account_projects'][number]['_user_account_project_roles'][number] & {
      teams?: UserTeams;
    }
  >;
  availableProjectRoles?: ProjectProductRoles;
};

export type ShortOrgType = Pick<
  NonNullable<NonNullable<GetAccountDetailsQuery['admin_']>['accountById']>,
  'id' | 'name' | 'parentAccount' | '__typename' | 'roles'
> & {
  // computed property to determine rowspan for org column. (required to render permissions table properly)
  orgRowSpan?: number;
  showAddProjectButton?: boolean;
  assignedProjects?: UserAccountProject[];
  accountRoles?: AccountRoles;
  assignedTeams?: UserTeams;
  availableAccountRoles?: AccountRoles;
  availableTeams?: AccountTeams;
  availableProjects?: AccountProjects;
  isPrimaryAccount: boolean;
};

export type OrgRowSpanCalculatorType = {
  assignedProjects: ShortOrgType['assignedProjects'];
  assignedProjectIds: string[];
  orgRowSpan: number;
};

export type UserDetailUpdates = {
  hasPendingUpdates: boolean;
  // Project level roles
  projectRolesUpdate?: ProjectRolesUpdate[];
  // Org level updates
  roleIdsToAdd?: string[];
  roleIdsToRemove?: string[];
  teamIdsToAdd?: string[];
  teamIdsToRemove?: string[];
  projectIdsToAdd?: string[];
  projectIdsToRemove?: string[];
};

export const userDetailUpdateInitialState: UserDetailUpdates = {
  hasPendingUpdates: false,
  projectRolesUpdate: [],
  roleIdsToAdd: [],
  roleIdsToRemove: [],
  teamIdsToAdd: [],
  teamIdsToRemove: [],
  projectIdsToAdd: [],
  projectIdsToRemove: [],
};

export type UserDetailsType = Pick<
  NonNullable<GetUserDetailsQuery['tenancy_users_by_pk']>,
  'id' | 'firstName' | 'lastName' | 'userName' | 'picture' | 'settings'
> & {
  orgs: ShortOrgType[];
  canManageAccess: boolean;
};
// #endregion Types

// #region constants
export const ProductNames = {
  PLATFORM: 'PLATFORM',
  ORGANIZATION: 'ORGANIZATION',
  AGENT_ASSIST: 'AGENT_ASSIST',
  ANALYTICS: 'ANALYTICS',
};

export const OrganizationRoleNames = {
  SUPER_ADMIN: 'Super Admin',
  ADMIN: 'Organization Admin',
  MANAGER: 'Organization Manager',
};

export const AnalyticsRoleNames = {
  ANALYTICS_ADMIN: 'Analytics Admin',
  ANALYTICS_MEMBER: 'Analytics Member',
};

export const AgentAssistRoleNames = {
  AGENT_ASSIST_ADMIN: 'Agent Manager',
  AGENT_ASSIST_AGENT: 'Agent',
};
// #endregion constants

// #region - Helper methods
export const processTeamInheritedDetails = ({
  teamDetails,
  selectedUserDetails,
}: {
  teamDetails: GetTeamProjectsDetailsQuery['tenancy_teams_by_pk'];
  selectedUserDetails: UserDetailsType;
}): UserDetailsType => {
  if (!selectedUserDetails) return selectedUserDetails;
  teamDetails?.team_accounts.forEach((team_account) => {
    team_account._team_account_roles.forEach((team_account_role) => {
      selectedUserDetails?.orgs.forEach((org) => {
        const isExistingTeamRole = org.accountRoles?.find(
          (accountRole) => accountRole.role.id === team_account_role.role.id
        );
        if (!isExistingTeamRole) {
          org.accountRoles?.push({
            role: team_account_role.role,
            teams: [
              {
                team: {
                  id: teamDetails.id,
                  name: teamDetails.name,
                  team_accounts: teamDetails.team_accounts,
                },
              },
            ],
          });
        }
      });
    });

    team_account.team_account_projects.forEach((team_account_project) => {
      const projectOrg = selectedUserDetails.orgs.find(
        (org) => org.id === team_account_project.project.accountId
      );
      if (!projectOrg) return;

      const existingProjectIndex = (
        projectOrg.assignedProjects || []
      ).findIndex(
        (assignedProject) =>
          assignedProject.project.id === team_account_project.project.id
      );

      if (existingProjectIndex >= 0) {
        const existingProject =
          projectOrg.assignedProjects![existingProjectIndex];

        team_account_project._team_account_project_roles.forEach(
          (_team_account_project_role) => {
            const existingInheritedTeamIndex =
              existingProject._user_account_project_roles.findIndex(
                (_user_account_project_role) =>
                  _user_account_project_role.role.id ===
                    _team_account_project_role.role.id &&
                  !!_user_account_project_role.teams
              );

            if (existingInheritedTeamIndex >= 0) {
              existingProject._user_account_project_roles[
                existingInheritedTeamIndex
              ].teams?.push({
                team: {
                  id: teamDetails.id,
                  name: teamDetails.name,
                  team_accounts: teamDetails.team_accounts,
                },
              });
            } else {
              existingProject._user_account_project_roles.push({
                role: _team_account_project_role.role,
                teams: [
                  {
                    team: {
                      id: teamDetails.id,
                      name: teamDetails.name,
                      team_accounts: teamDetails.team_accounts,
                    },
                  },
                ],
              });
            }
            existingProject._user_account_project_roles = sortBy(
              existingProject._user_account_project_roles,
              'teams'
            );
          }
        );
      } else {
        const availableProjectRoles =
          team_account_project.project.project_product_settings?.reduce(
            (allProductRoles, currentProduct) => {
              projectOrg.roles?.rows
                ?.filter((role) => role.productId === currentProduct.product.id)
                .forEach((orgProductRole) => {
                  allProductRoles?.push({
                    id: orgProductRole.id,
                    name: orgProductRole.name,
                    productId: orgProductRole.productId,
                    product: orgProductRole.product,
                  });
                });
              return allProductRoles;
            },
            [] as ProjectProductRoles
          );
        const projectToAdd: UserAccountProject = {
          id: team_account_project.project.id,

          availableProjectRoles: availableProjectRoles,
          _user_account_project_roles:
            team_account_project._team_account_project_roles.map(
              (_team_account_project_role) => ({
                role: _team_account_project_role.role,
                teams: [
                  {
                    team: {
                      id: teamDetails.id,
                      name: teamDetails.name,
                      team_accounts: teamDetails.team_accounts,
                    },
                  },
                ],
              })
            ),
          project: {
            id: team_account_project.project.id,
            name: team_account_project.project.name,
            accountId: team_account_project.project.accountId,
            project_product_settings:
              team_account_project.project.project_product_settings,
          },
        };

        // Add project to org object's assignedProjects property.
        projectOrg.assignedProjects?.push(projectToAdd);

        // Remove project from org object's availableProjects property.
        projectOrg.availableProjects = projectOrg.availableProjects?.filter(
          (availableProject) =>
            availableProject.id !== team_account_project.project?.id
        );

        // Recalculate rowSpan property for org.
        const canAddMoreProjects =
          (projectOrg.availableProjects?.length || 0) > 0;

        projectOrg.orgRowSpan =
          (projectOrg.orgRowSpan || 1) +
          Math.max(
            projectToAdd.project.project_product_settings.length - 1,
            0
          ) +
          ((canAddMoreProjects && 1) || 0);
      }
    });
  });
  return selectedUserDetails;
};

export const handleAddProjectAccess = assign(
  (ctx: ManagerContext, event: ManagerEvents) => {
    if (event.type !== 'manager.userDetails.addProject') return;
    const { project } = event;
    // @todo - fix usage of selectedUser. it should be present by default.
    if (!ctx.selectedUser) return ctx;
    if (ctx.userDetails[ctx.selectedUser]?.userDetailUpdates.projectIdsToAdd) {
      (
        ctx.userDetails[ctx.selectedUser]!.userDetailUpdates.projectIdsToAdd ||
        []
      ).push(project.id);
    }
    const allOrgs = ctx.userDetails?.[ctx.selectedUser!]?.state?.orgs || [];

    const orgIndexToUpdate = allOrgs.findIndex(
      (org) => org.id === project.accountId
    );

    const orgAvailableRoleDetails =
      ctx.userDetails?.[ctx.selectedUser]?.state?.orgs[orgIndexToUpdate]?.roles
        ?.rows;

    if (orgIndexToUpdate >= 0) {
      const { projectProductSettings } = project;
      // Recreating project_product_settings object to align types with 2 different sources.
      const project_product_settings: ProjectProductSettings = [];

      // Calculate all available roles for the project
      const availableProjectRoles = projectProductSettings?.reduce(
        (allProductRoles, currentProduct) => {
          orgAvailableRoleDetails
            ?.filter((role) => role.productId === currentProduct.productId)
            .forEach((orgProductRole) => {
              allProductRoles?.push({
                id: orgProductRole.id,
                name: orgProductRole.name,
                productId: orgProductRole.productId,
                product: orgProductRole.product,
              });
            });

          // Aligning types with 2 different sources of project_product_settings.
          project_product_settings.push({
            product: {
              name: currentProduct.product?.name || '',
              id: currentProduct.product?.id || '',
              displayName: currentProduct.product?.displayName || '',
            },
          });

          return allProductRoles;
        },
        [] as ProjectProductRoles
      );

      const projectToAdd: UserAccountProject = {
        id: project.id,
        availableProjectRoles,
        _user_account_project_roles: [],
        project: {
          id: project.id,
          name: project.name,
          accountId: project.accountId,
          project_product_settings,
        },
      };

      // Add project to org object's assignedProjects property.
      allOrgs[orgIndexToUpdate].assignedProjects?.push(projectToAdd);

      // Remove project from org object's availableProjects property.
      allOrgs[orgIndexToUpdate].availableProjects = allOrgs[
        orgIndexToUpdate
      ].availableProjects?.filter(
        (availableProject) => availableProject.id !== project.id
      );

      // Recalculate rowSpan property for org.
      const canAddMoreProjects =
        (allOrgs[orgIndexToUpdate].availableProjects?.length || 0) > 0;

      allOrgs[orgIndexToUpdate].orgRowSpan =
        (allOrgs[orgIndexToUpdate].orgRowSpan || 1) +
        Math.max(projectToAdd.project.project_product_settings.length - 1, 0) +
        ((canAddMoreProjects && 1) || 0);
    }

    ctx.userDetails[ctx.selectedUser]!.userDetailUpdates.hasPendingUpdates =
      hasPendingUpdates(ctx.userDetails[ctx.selectedUser].userDetailUpdates);
    return ctx;
  }
);

export const removeTeam = assign(
  (ctx: ManagerContext, event: ManagerEvents) => {
    if (event.type !== 'manager.userDetails.removeTeam' || !ctx.selectedUser)
      return;
    const { orgId, team: teamToRemove } = event;
    const selectUserDetails = ctx.userDetails[ctx.selectedUser];

    const orgToUpdate = selectUserDetails?.state?.orgs.find(
      (org) => org.id === orgId
    );

    if (orgToUpdate) {
      const teamIndexToRemove = (orgToUpdate.assignedTeams || []).findIndex(
        (team) => team.team.id === teamToRemove.team.id
      );

      if (
        teamIndexToRemove >= 0 &&
        orgToUpdate.assignedTeams &&
        orgToUpdate.assignedTeams[teamIndexToRemove]
      ) {
        orgToUpdate.availableTeams?.push({
          id: orgToUpdate.assignedTeams?.[teamIndexToRemove]?.team?.id,
          name: orgToUpdate.assignedTeams?.[teamIndexToRemove]?.team?.name,
          assignedProjects: orgToUpdate.assignedTeams[
            teamIndexToRemove
          ]?.team?.team_accounts[0].team_account_projects.map(
            (team_account_project) => ({
              id: team_account_project.project.id,
              name: team_account_project.project.name,
              accountId: team_account_project.project.accountId,
            })
          ),
          assignedRoles: orgToUpdate.assignedTeams[
            teamIndexToRemove
          ]?.team?.team_accounts[0]._team_account_roles.map(
            (_team_account_role) => ({
              id: _team_account_role.role.id,
              name: _team_account_role.role.name,
              productId: _team_account_role.role.productId,
              product: {
                name: (_team_account_role.role.product?.name ||
                  '') as ProductName,
                id: _team_account_role.role.product?.id || '',
                displayName: _team_account_role.role.product?.displayName || '',
              },
            })
          ),
        });

        orgToUpdate.availableTeams = sortBy(orgToUpdate.availableTeams, 'name');

        orgToUpdate.assignedTeams?.splice(teamIndexToRemove, 1);
      }

      ctx.userDetails[ctx.selectedUser]!.state!.orgs = ctx.userDetails[
        ctx.selectedUser
      ]!.state!.orgs.map((org) => {
        org.accountRoles = compact(
          org.accountRoles?.map((accountRole) => {
            accountRole.teams = accountRole.teams?.filter(
              (team) => team.team.id !== teamToRemove.team.id
            );
            if (accountRole.teams?.length === 0) {
              return null;
            }
            return accountRole;
          })
        );
        return org;
      });
      teamToRemove.team.team_accounts[0].team_account_projects.forEach(
        (team_account_project) => {
          const orgToUpdateForInheritedProjectRoles =
            selectUserDetails?.state?.orgs.find(
              (org) => org.id === team_account_project.project.accountId
            );
          if (orgToUpdateForInheritedProjectRoles) {
            const projectToUpdate =
              orgToUpdateForInheritedProjectRoles?.assignedProjects?.find(
                (assignedProject) =>
                  assignedProject.project.id === team_account_project.project.id
              );
            if (projectToUpdate) {
              projectToUpdate._user_account_project_roles = sortBy(
                projectToUpdate._user_account_project_roles.filter(
                  (_user_account_project_role) =>
                    !_user_account_project_role.teams ||
                    _user_account_project_role.teams.find(
                      (team) => team.team.id !== teamToRemove.team.id
                    )
                ),
                'teams'
              );
            }
          }
        }
      );
    }

    if (!selectUserDetails?.userDetailUpdates.teamIdsToRemove) {
      selectUserDetails.userDetailUpdates.teamIdsToRemove = [];
    }

    if (
      !(selectUserDetails?.userDetailUpdates.teamIdsToAdd || []).includes(
        teamToRemove.team.id
      )
    ) {
      (selectUserDetails?.userDetailUpdates.teamIdsToRemove || []).push(
        teamToRemove.team.id
      );
    } else {
      selectUserDetails.userDetailUpdates.teamIdsToAdd = (
        selectUserDetails?.userDetailUpdates.teamIdsToAdd || []
      ).filter((teamId) => teamId !== teamToRemove.team.id);
    }

    selectUserDetails.userDetailUpdates.hasPendingUpdates = hasPendingUpdates(
      selectUserDetails.userDetailUpdates
    );

    return ctx;
  }
);

export const updateProjectProductRole = assign(
  (ctx: ManagerContext, event: ManagerEvents) => {
    if (
      event.type !== 'manager.userDetails.updateProjectProductRole' ||
      !ctx.selectedUser
    )
      return;
    const { projectId, orgId, role, operation } = event;
    const orgToUpdate = ctx.userDetails[ctx.selectedUser]?.state?.orgs.find(
      (org) => org.id === orgId
    );
    const projectToUpdate = orgToUpdate?.assignedProjects?.find(
      (project) => project.project.id === projectId
    );
    const selectedUserProjectRoleUpdate =
      ctx.userDetails[ctx.selectedUser]!.userDetailUpdates;
    const existingProjectRoleUpdateIndex = (
      selectedUserProjectRoleUpdate.projectRolesUpdate || []
    ).findIndex((_projectRoleUpdate) => _projectRoleUpdate.id === projectId);

    const projectRoleUpdate: ProjectRolesUpdate = {
      id: projectId,
      rolesToRemove:
        (existingProjectRoleUpdateIndex >= 0 &&
          selectedUserProjectRoleUpdate.projectRolesUpdate?.[
            existingProjectRoleUpdateIndex
          ]?.rolesToRemove) ||
        [],
      rolesToAdd:
        (existingProjectRoleUpdateIndex >= 0 &&
          selectedUserProjectRoleUpdate.projectRolesUpdate?.[
            existingProjectRoleUpdateIndex
          ]?.rolesToAdd) ||
        [],
    };

    if (projectToUpdate) {
      projectToUpdate._user_account_project_roles.forEach(
        (_user_account_project_role) => {
          if (
            _user_account_project_role.role.productId === role.productId &&
            // Only process non-inherited roles
            (_user_account_project_role.teams || []).length === 0
          ) {
            if (
              !projectToUpdate.availableProjectRoles?.find(
                (availableRole) =>
                  availableRole.id === _user_account_project_role.role.id
              )
            ) {
              projectToUpdate.availableProjectRoles?.push({
                id: _user_account_project_role.role.id,
                name: _user_account_project_role.role.name || '',
                productId: _user_account_project_role.role.productId || '',
                product: {
                  id: _user_account_project_role.role.product?.id || '',
                  name: (_user_account_project_role.role.product?.name ||
                    '') as ProductName,
                  displayName:
                    _user_account_project_role.role.product?.displayName || '',
                },
              });

              if (
                !projectRoleUpdate.rolesToAdd?.includes(
                  _user_account_project_role.role.id
                )
              ) {
                projectRoleUpdate.rolesToRemove?.push(
                  _user_account_project_role.role.id
                );
              } else {
                projectRoleUpdate.rolesToAdd =
                  projectRoleUpdate.rolesToAdd?.filter(
                    (roleId) => roleId !== _user_account_project_role.role.id
                  ) || [];
              }
            }
          }
        }
      );

      projectToUpdate._user_account_project_roles =
        projectToUpdate._user_account_project_roles.filter(
          (_user_account_project_role) => {
            if ((_user_account_project_role.teams?.length || 0) > 0) {
              return true;
            }
            return _user_account_project_role.role.productId !== role.productId;
          }
        );

      if (operation === 'add' || operation === 'update') {
        projectToUpdate._user_account_project_roles.push({
          role: {
            id: role.id || '',
            name: role.name || '',
            productId: role.productId || '',
            product: {
              name: role.product?.name || '',
              id: role.product?.id || '',
              displayName: role.product?.displayName || '',
            },
          },
        });
        projectToUpdate.availableProjectRoles =
          projectToUpdate.availableProjectRoles?.filter(
            (availableProjectRole) => availableProjectRole.id !== role.id
          );
      }

      if (operation !== 'remove') {
        if (
          !projectRoleUpdate.rolesToAdd?.includes(role.id || '') &&
          !projectRoleUpdate.rolesToRemove?.includes(role.id || '')
        ) {
          projectRoleUpdate.rolesToAdd?.push(role.id || '');
        } else {
          projectRoleUpdate.rolesToRemove =
            projectRoleUpdate.rolesToRemove?.filter(
              (roleId) => roleId !== role.id
            ) || [];
        }
      }

      projectToUpdate._user_account_project_roles = sortBy(
        projectToUpdate._user_account_project_roles,
        'teams'
      );

      const projectRolesUpdateCtx =
        ctx.userDetails[ctx.selectedUser]!.userDetailUpdates.projectRolesUpdate;
      if (!projectRolesUpdateCtx) return;

      if (existingProjectRoleUpdateIndex >= 0) {
        projectRolesUpdateCtx[existingProjectRoleUpdateIndex] =
          projectRoleUpdate;
      } else {
        projectRolesUpdateCtx.push(projectRoleUpdate);
      }

      ctx.userDetails[ctx.selectedUser]!.userDetailUpdates.projectRolesUpdate =
        projectRolesUpdateCtx.filter(
          (roleUpdate) =>
            (roleUpdate.rolesToAdd || []).length > 0 ||
            (roleUpdate.rolesToRemove || []).length > 0
        );

      ctx.userDetails[ctx.selectedUser]!.userDetailUpdates.hasPendingUpdates =
        hasPendingUpdates(ctx.userDetails[ctx.selectedUser].userDetailUpdates);
    }

    return ctx;
  }
);

export const hasPendingUpdates = (
  userDetailUpdates: UserDetailUpdates
): boolean => {
  return (
    (userDetailUpdates.projectRolesUpdate || []).length > 0 ||
    (userDetailUpdates.roleIdsToAdd || []).length > 0 ||
    (userDetailUpdates.roleIdsToRemove || []).length > 0 ||
    (userDetailUpdates.projectIdsToAdd || []).length > 0 ||
    (userDetailUpdates.projectIdsToRemove || []).length > 0 ||
    (userDetailUpdates.teamIdsToAdd || []).length > 0 ||
    (userDetailUpdates.teamIdsToRemove || []).length > 0
  );
};
// #endregion - Helper methods
