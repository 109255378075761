import { FilePondFile, ProcessServerConfigFunction } from 'filepond';
import { html, unsafeCSS } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { ifDefined } from 'lit/directives/if-defined.js';
import { property, state } from 'lit/decorators.js';
import { type UploadedFile } from './filepond-wrapper.wc';
// eslint-disable-next-line no-duplicate-imports
import './filepond-wrapper.wc';

import { EntityTypes } from './filepond.types';
import { BaseElement, customElement } from '../../base-element';
import { Translate } from '../../base-element/mixins/translation-mixin';
import styles from './filepond.scss?inline';

interface FilepondProps {
  accountId?: string;
  entityId?: string;
  entityType?: EntityTypes;
  pictureSrc?: string;
  name?: string;
  readonly?: boolean;
  previewLabel?: string;
  previewCta?: string;
  accept?: string[];
  onChange?: ({ url, pictureId }: { url?: string; pictureId?: string }) => void;
}

type ConfigureProcessFunctionParams = Pick<
  FilepondProps,
  'accountId' | 'entityId' | 'entityType' | 'onChange'
>;

type ProcessServerConfigFunctionParams =
  Parameters<ProcessServerConfigFunction>;

interface ConfigureProcessFunction {
  (props: ConfigureProcessFunctionParams): ProcessServerConfigFunction;
}

type SetProfilePictureParams = ConfigureProcessFunctionParams & {
  file?: ProcessServerConfigFunctionParams[1];
  load?: ProcessServerConfigFunctionParams[3];
  error?: ProcessServerConfigFunctionParams[4];
  progress?: ProcessServerConfigFunctionParams[5];
  abort?: ProcessServerConfigFunctionParams[6];
};

interface SetProfilePicture {
  (props: SetProfilePictureParams): {
    abort: SetProfilePictureParams['abort'];
  };
}

type UploadResponse = {
  accountId?: string;
  appletId?: string;
  id?: string;
  projectId?: string;
  publicId?: string;
  url?: string;
  userId?: string;
  createdBy?: string;
  createdAt?: Date;
};

const removeFile = ({
  accountId,
  entityId,
  entityType,
}: {
  accountId?: string;
  entityId?: string;
  entityType?: EntityTypes;
}) => {
  if (!accountId || !entityId || !entityType) return;

  const url = `${import.meta.env.FE_ADMIN_URL}/rest/upload-file-picture/${accountId}/${entityType}/${entityId}`;
  const request = new XMLHttpRequest();
  request.withCredentials = true;
  request.open('POST', url);
  request.send(undefined);
};

const uploadFile: SetProfilePicture = ({
  accountId,
  entityId,
  entityType = EntityTypes.USER,
  file,
  load,
  error,
  progress,
  abort,
  onChange,
}) => {
  const url = entityId
    ? `${
        import.meta.env.FE_ADMIN_URL
      }/rest/upload-file-picture/${accountId}/${entityType}/${entityId}`
    : `${import.meta.env.FE_ADMIN_URL}/rest/upload-file/${accountId}`;

  const request = new XMLHttpRequest();
  request.withCredentials = true;
  request.open('POST', url);

  // Should call the progress method to update the progress to 100% before calling load
  // Setting computable to false switches the loading indicator to infinite mode
  request.upload.onprogress = (e) => {
    progress?.(e.lengthComputable, e.loaded, e.total);
  };

  // Should call the load method when done and pass the returned server file id
  // this server file id is then used later on when reverting or restoring a file
  // so your server knows which file to return without exposing that info to the client
  request.onload = () => {
    if (request.status >= 200 && request.status < 300) {
      // the load method accepts either a string (id) or an object
      load?.(request.responseText);

      const result: UploadResponse = JSON.parse(
        request.response
      ) as UploadResponse;
      const { url: imageUrl, id: pictureId } = result;
      onChange?.({
        url: imageUrl,
        pictureId,
      });
    } else {
      // Can call the error method if something is wrong, should exit after
      error?.(file ? 'Error uploading file' : 'Error deleting file');
    }
  };

  const formData = new FormData();

  if (file) {
    formData.append('file', file);
  }

  const body = file ? formData : undefined;

  request.send(body);

  // Should expose an abort method so the request can be cancelled
  return {
    abort: () => {
      // This function is entered if the user has tapped the cancel button
      request.abort();

      // Let FilePond know the request has been cancelled
      abort?.();
    },
  };
};

const configureProcessFunction: ConfigureProcessFunction =
  ({ accountId, entityId, entityType, onChange }) =>
  (...args) => {
    const [, file, , load, error, progress, abort] = args;

    uploadFile({
      accountId,
      entityId,
      entityType,
      file,
      load,
      error,
      progress,
      abort,
      onChange,
    });
  };

@customElement('ps-filepond')
export class FilepondWC extends Translate(BaseElement) {
  static styles = unsafeCSS(styles);

  @property({ type: Array }) accept?: string[] = [];

  @property({ reflect: true, attribute: 'account-id' }) accountId?: string;

  @property({ reflect: true, attribute: 'entity-id' }) entityId?: string;

  @property({ reflect: true, attribute: 'entity-type' })
  entityType?: EntityTypes;

  @property({ reflect: true, attribute: 'picture-src' }) pictureSrc?: string;

  @property({ reflect: true }) label = '';

  @property() name?: string;

  @property({ reflect: true, attribute: 'preview-label' })
  previewLabel = 'Drag a photo here';

  @property({ reflect: true, attribute: 'preview-cta' }) previewCta =
    'Select a file';

  @property({ reflect: true }) onChange?: ({
    url,
    pictureId,
  }: {
    url?: string;
    pictureId?: string;
  }) => void;

  @property({ type: Boolean }) readonly?: boolean;

  @property({ type: Boolean, attribute: 'allow-multiple' })
  allowMultiple?: boolean;

  @property({ type: Boolean }) required?: boolean;

  @property({ type: Boolean, reflect: true }) vertical = false;

  @state() files: UploadedFile[] = [];

  removeObserver: MutationObserver;

  connectedCallback() {
    super.connectedCallback?.();
    this.addEventListener('click', this.onRemoveButtonClick);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.removeEventListener('click', this.onRemoveButtonClick);
  }

  onRemoveButtonClick = (e: MouseEvent) => {
    const elementClicked = e.composedPath()[0] as HTMLElement;

    if (elementClicked.closest('.c-fp-upload__preview-remove')) {
      this.removeFileHandler();
    }
  };

  removeFileHandler = () => {
    this.pictureSrc = undefined;
    removeFile({
      entityId: this.entityId,
      entityType: this.entityType,
      accountId: this.accountId,
    });
    this.onChange?.({ url: undefined });
  };

  updateFileHandler = (e: FilePondFile[]) => {
    this.files = e[0]?.file ? [e[0]?.file] : [];
  };

  render() {
    const labelFileProcessingComplete =
      this.entityType === EntityTypes.USER
        ? 'Success, you look great!'
        : 'Success, image uploaded!';

    if (this.readonly)
      return html`
        <div
          class=${classMap({
            'c-fp-upload': true,
            'c-fp-upload--vertical': this.vertical,
            'c-fp-upload--readonly': true,
            'c-fp-upload--readonly-has-image': !!this.pictureSrc,
            [`c-fp-upload--${this.entityType}`]: !!this.entityType,
          })}
        >
          <div class=${classMap({
            'c-fp-upload__preview': true,
            'c-fp-upload__preview--vertical': this.vertical,
          })}>
            <div class="c-fp-upload__preview-icon">
              ${
                this.pictureSrc
                  ? html`<ps-avatar
                      size="2xlarge"
                      src=${this.pictureSrc}
                    ></ps-avatar>`
                  : html`<ps-icon
                      name="arrow-up"
                      weight="bold"
                      size="small"
                    ></ps-icon>`
              }
            </div>
            <div class="c-fp-upload__preview-content">
              <p class="c-fp-upload__preview-text">
                ${this.previewLabel}
              </p>
              <p class="c-fp-upload__preview-info">
                ${this.previewCta}
              </span></p>
            </div>
          </div>
        </div>
    `;

    return html`
      <div
        class=${`c-fp-upload c-fp-upload--${this.entityType?.toLowerCase()} ${this.vertical ? 'c-fp-upload--vertical' : ''}`}
      >
        ${this.label
          ? html` <p class="c-fp-upload__label-text">${this.label}</p> `
          : ''}
        <ps-filepond-wrapper
          .acceptedFileTypes=${this.accept}
          ?disabled=${this.readonly}
          .files=${this.files}
          name=${ifDefined(this.name)}
          labelIdle=${`
              <div class="${`c-fp-upload__preview ${this.vertical ? 'c-fp-upload__preview--vertical' : ''}`}">
                <div class="c-fp-upload__preview-icon">
                  ${
                    this.pictureSrc
                      ? `<ps-avatar size="2xlarge" src=${this.pictureSrc}></ps-avatar>`
                      : `<ps-icon name="arrow-up" weight="bold" size="small"></ps-icon>`
                  }
                </div>
                <div class="c-fp-upload__preview-content">
                  <p class="c-fp-upload__preview-text">
                    ${this.pictureSrc ? labelFileProcessingComplete : this.previewLabel}
                  </p>
                  <p class="c-fp-upload__preview-info">
                    ${this.previewCta}
                  </span></p>
                </div>
                ${
                  this.pictureSrc
                    ? `
                      <button type="button" class="c-fp-upload__preview-remove">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
                          <path d="m5 19 7-7m7-7-7 7m0 0 7 7L5 5" stroke="currentColor" stroke-width="1"></path>
                        </svg>
                      </button>
                    `
                    : ''
                }
              </div>
            `}
          labelFileProcessing="Uploading..."
          labelFileProcessingComplete=${labelFileProcessingComplete}
          .onremovefile=${this.removeFileHandler}
          .onupdatefiles=${this.updateFileHandler}
          .server=${{
            process: configureProcessFunction({
              accountId: this.accountId,
              entityId: this.entityId,
              entityType: this.entityType,
              onChange: this.onChange,
            }),
          }}
        ></ps-filepond-wrapper>
      </div>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'ps-filepond': FilepondWC;
  }
  enum PSElementTagNameMap {
    'ps-filepond' = 'ps-filepond',
  }
}
